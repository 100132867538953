import ParentStore from '../stores/ParentStore.js';
import logger from 'utils/logger';
import { decorate, computed, observable, action } from 'mobx';
import gql from 'graphql-tag';
import gqlUtils from '@mqd/graphql-utils';
import ApolloClient from 'apollo-boost';
import { gqlClient, secureStorage } from '@mq/volt-amc-container';
import currentUserStore from 'stores/CurrentUserStore';

const { mockClient } = gqlUtils;

class GqlApi extends ParentStore {
  constructor() {
    super();
    this.currentGqlClient = secureStorage.getItem('currentGqlClient') || 'Live';
    this.client = gqlClient();
  }

  get gqlClientUrl() {
    switch (this.currentGqlClient) {
      case 'Stubbed':
        return '';
      case 'Local':
        return 'http://localhost:4000/graphql';
      case 'Live':
        return this.usePathOrAppApiProxy('/graphql');
      default:
        logger.error('No environment was selected (showing stubbed data)');
        return '';
    }
  }

  get gqlClientName(): string {
    if (this.usingGqlEndpoint) {
      return `${this.gqlClientUrl}`;
    } else {
      return 'Mock Client';
    }
  }

  async gqlMutation(
    mutation: string,
    variables: Object,
    fragments: Array<*> = [],
    fetchPolicy = 'no-cache',
    rethrowError = false
  ) {
    mutation = gql`
      ${mutation}
    `;
    if (!this.gqlClient) {
      throw new Error(
        `No gqlClient defined for ${this.constructor ? this.constructor.name : 'Unknown Class'}`
      );
    }
    try {
      return await this.gqlClient.mutate({
        mutation,
        variables,
        fetchPolicy,
      });
    } catch (e) {
      logger.error(e);
      if (rethrowError) {
        throw new Error(e);
      }
    }
  }

  async gqlQuery(
    query: string,
    variables: Object,
    fragments: Array<*> = [],
    fetchPolicy = 'no-cache'
  ) {
    query = gql`
      ${query}
    `;
    if (!this.gqlClient) {
      throw new Error(
        `No gqlClient defined for ${this.constructor ? this.constructor.name : 'Unknown Class'}`
      );
    }
    try {
      return await this.gqlClient.query({
        query,
        variables,
        fetchPolicy,
      });
    } catch (e) {
      logger.error(e);
    }
  }

  get gqlClient(): Object {
    if (this.usingGqlEndpoint) {
      return this.client;
    } else {
      return mockClient;
    }
  }

  get gqlJanusClient(): Object {
    if (this.usingGqlEndpoint) {
      return new ApolloClient({
        uri: this.gqlClientUrl,
        request: (operation) => {
          const headers = {
            'x-marqeta-access-token-type': 'janus',
          };
          const { webToken } = currentUserStore.userStore;
          if (webToken) {
            const encodedToken = btoa(`DIVA_APP:${webToken || ''}`);
            headers.authorization = `Basic ${encodedToken}`;
          }
          operation.setContext({ headers });
        },
        credentials: 'include',
      });
    } else {
      return mockClient;
    }
  }

  get gqlProgramShortCode(): string {
    return currentUserStore.userStore.activeProgramShortCode;
  }

  get usingGqlEndpoint() {
    return this.gqlClientUrl.startsWith('http') || this.gqlClientUrl.startsWith('/');
  }
}

decorate(GqlApi, {
  currentGqlClient: observable,
  client: observable,
  gqlQuery: action.bound,
  gqlMutation: action.bound,
  setClient: action.bound,

  gqlClientUrl: computed,
  gqlClient: computed,
  gqlJanusClient: computed,
  gqlClientName: computed,
  usingGqlEndpoint: computed,
  gqlProgramShortCode: computed,
});
const api = new GqlApi();
export default api;
