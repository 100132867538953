import gql from 'graphql-tag';

export const productPolicyFragment = `
    token
    cardProducts {
        token
        name
        level
    }
    name
    description
    classification
    productType
    productSubType
    currencyCode
    creditLine {
        min
        max
    }
    usage
    interestCalculation {
        interestApplication
        interestOnGraceReactivation
        graceDaysApplication
        method
        dayCount
        minimumInterest
        excludeTranTypes
        applicationOfCredits {
            cycleType
            day
        }
    }
    payments {
        billingCycleDay
        dueDay
        allocationOrder
        minPaymentCalculation {
            minPaymentPercentage {
                percentageOfBalance
            }
            minPaymentFlatAmount
        }
    }
    createdTime
`;

export const selectProductsQuery = gql`
  query {
    parentCreditProducts {
      data {
        token
        name
        status
        creditLine {
          min
          max
        }
        createdTime
        config {
          fees
        }
      }
    }
  }
`;

export const createOfferMutation = gql`
  mutation createOffer(
    $name: String
    $description: String
    $status: OFFER_STATUS
    $product_token: String
    $channels: [OFFER_CHANNEL]
    $gracePeriodDays: Int
    $paymentDueDay: Int
    $aprs: [APR_input]
    $fees: [FEE_input]
    $rewards: [CREDIT_REWARD_INPUT]
    $comment: String
  ) {
    createOffer(
      name: $name
      description: $description
      status: $status
      product_token: $product_token
      channels: $channels
      gracePeriodDays: $gracePeriodDays
      paymentDueDay: $paymentDueDay
      aprs: $aprs
      fees: $fees
      rewards: $rewards
      comment: $comment
    ) {
      token
    }
  }
`;

export const updateOfferMutation = gql`
  mutation updateOffer(
    $token: String
    $name: String
    $description: String
    $status: OFFER_STATUS
    $product_token: String
    $channels: [OFFER_CHANNEL]
    $gracePeriodDays: Int
    $paymentDueDay: Int
    $aprs: [APR_input]
    $fees: [FEE_input]
    $rewards: [CREDIT_REWARD_INPUT]
    $comment: String
  ) {
    updateOffer(
      token: $token
      name: $name
      description: $description
      status: $status
      product_token: $product_token
      channels: $channels
      gracePeriodDays: $gracePeriodDays
      paymentDueDay: $paymentDueDay
      aprs: $aprs
      fees: $fees
      rewards: $rewards
      comment: $comment
    ) {
      token
      name
      description
      status
      aprs {
        minimum
        maximum
      }
      productToken {
        token
      }
      fees {
        type
        method
        value
      }
      rewards {
        type
        method
        value
      }
      createdTime
      updatedTime
    }
  }
`;

export const updateOfferStatusMutation = gql`
  mutation updateOfferStatus($token: String, $status: OFFER_STATUS, $comment: String) {
    updateOfferStatus(token: $token, status: $status, comment: $comment) {
      token
      status
    }
  }
`;

export const getCreditAccountsQuery = gql`
  query creditAccounts($start_index: Int, $filters: CreditAccountsFilters, $includeSsn: Boolean!) {
    creditAccounts(start_index: $start_index, filters: $filters, includeSsn: $includeSsn) {
      data {
        account {
          token
          name
          status
          accountSubStatuses
          userSubStatuses
        }
        cardInstrumentType
        cardLastFour
        firstName
        lastName
        ssnlastFour @include(if: $includeSsn)
        phone
        email
      }
      is_more
      start_index
    }
  }
`;

export const getCreditUsersQuery = gql`
  query creditUsers($start_index: Int, $filters: CreditUserFilters) {
    creditUsers(start_index: $start_index, filters: $filters) {
      data {
        firstName
        lastName
        phoneNumber
        emailAddress
        userToken
      }
      is_more
      start_index
      end_index
      count
    }
  }
`;

export const getCreditAccountQuery = gql`
  query creditAccount($token: String!, $includeSsn: Boolean!) {
    creditAccount(token: $token) {
      token
      name
      description
      status
      type
      accountSubStatuses
      userSubStatuses
      purchaseBalance
      availableCredit
      externalOfferId
      activationTime
      creditLine {
        limit
        currencyCode
      }
      remainingStatementBalance
      config {
        billingCycleDay
        paymentDueDay
        fees {
          type
          active
          schedule {
            value
            method
            effectiveDate
          }
        }
        minPayment {
          overrideStartTime
          overrideEndTime
          minPaymentFlatAmount
          minPaymentPercentage
          active
        }
      }

      accountUsage {
        type
        aprs {
          type
          active
          schedule {
            value
            effectiveDate
          }
        }

        rewards {
          type
          method
          value
        }
      }

      createdTime
      updatedTime

      accountCards {
        token
        last_four
        instrument_type
        cvv_number
        state
        expiration_time
        cardholder {
          token
          first_name
          middle_name
          last_name
        }
      }

      offer {
        token
        name
        gracePeriodDays
      }
      creditProduct {
        token
        name
      }
      accountHolderToken
      accountHolderDetails {
        token
        first_name
        middle_name
        last_name
        gender
        birth_date
        email
        phone
        ssn
        address1
        address2
        city
        state
        country
        zip
        postal_code
        fullIdentificationNumber(type: "ssn", full_ssn: false) @include(if: $includeSsn) {
          ssn
        }
      }
      memos {
        memo
        createdBy
        createdTime
      }

      #hardcoded in gql
      paymentDueDate
      minimumPayment
      statementBalance
    }
  }
`;

export const getCreditAuthorizedUsersQuery = gql`
  query creditAuthorizedUsers($accountToken: String!) {
    creditAuthorizedUsers(accountToken: $accountToken) {
      token
      authorizedUsers {
        token
        firstName
        lastName
      }
    }
  }
`;

export const getCreditTransactionsByAccountQuery = gql`
  query creditTransactions(
    $accountToken: ID!
    $start_index: Int
    $filters: CreditTransactionsFilters
  ) {
    creditTransactions(accountToken: $accountToken, start_index: $start_index, filters: $filters) {
      data {
        token
        amount
        createdTime
        type
        memo
        computedStatus
        currencyCode
      }
      is_more
    }
  }
`;

export const getRelatedTransactionsQuery = gql`
  query relatedTransactions($token: ID!) {
    relatedTransactions(token: $token) {
      count
      total
      start_index
      end_index
      is_more
      data {
        type
        state
        token
        amount
        created_time
        name
        card_acceptor {
          name
        }
      }
    }
  }
`;

export const getCreditTransaction = gql`
  query creditTransaction($token: String!, $accountToken: String!, $isSupportAgent: Boolean!) {
    creditTransaction(token: $token, accountToken: $accountToken, isSupportAgent: $isSupportAgent) {
      token
      userToken
      id
      createdTime
      amount
      currencyCode
      memo
      status
      detailToken
      detailObject {
        pos {
          pan_entry_mode
        }
        paymentSourceToken
        state
        fee
        value
        method
        appliedToAmount
        statementToken
        statementOpeningDate
        statementClosingDate
        statementBalance
        averageDailyBalance
        gotoApr
        dailyPeriodicRate
        daysInBillingCycle
        interestAmount
        payment {
          method
        }
      }
      group
      type
      transactionId
      accountToken
      cardToken

      creditAccount {
        accountHolderToken
        accountHolderDetails {
          first_name
          last_name
          phone
        }
      }

      card {
        instrument_type
        expiration_time

        last_four @include(if: $isSupportAgent)
        support_agent_cvv @include(if: $isSupportAgent)

        state
        cardholder {
          token
          first_name
          last_name
          phone
          city
          state
          country
        }
      }
    }
  }
`;

// =========Payment queries=========
export const getCreditAccountPaymentQuery = gql`
  query creditAccountPayment($accountToken: String!, $token: String!) {
    creditAccountPayment(accountToken: $accountToken, token: $token) {
      token
      accountToken
      paymentSourceToken
      paymentScheduleToken
      currencyCode
      amount
      status
      method
      sourceAccount
      transactionId
      createdTime
      description
      transitions {
        token
        status
        created_time
      }
    }
  }
`;

export const getCreditAccountPaymentsQuery = gql`
  query creditAccountPayments($accountToken: String!, $start_index: Int) {
    creditAccountPayments(accountToken: $accountToken, start_index: $start_index) {
      data {
        token
        amount
        status
        method
        createdTime
        updatedTime
        description
        memo
        refundReasons
        cancelationReasons
        paymentSourceToken
      }
    }
  }
`;

export const getCreditAccountPaymentSourcesQuery = gql`
  query creditAccountPaymentSources($accountToken: String!, $start_index: Int) {
    creditAccountPaymentSources(accountToken: $accountToken, start_index: $start_index) {
      data {
        token
        sourceType
        name
        routingNumber
        bankName
        status
        accountSuffix
      }
      is_more
      start_index
    }
  }
`;

export const getCreditAccountPaymentSourceQuery = gql`
  query creditAccountPaymentSource($token: String!) {
    creditAccountPaymentSource(token: $token) {
      token
      sourceType
      name
      routingNumber
      bankName
      status
    }
  }
`;

export const getCreditAccountPaymentSchedulesQuery = gql`
  query creditAccountPaymentSchedules($accountToken: String!, $paymentScheduleToken: String!) {
    creditAccountPaymentSchedules(
      accountToken: $accountToken
      paymentScheduleToken: $paymentScheduleToken
    ) {
      amountCategory
    }
  }
`;

export const getCreditAccountListPaymentSchedulesQuery = gql`
  query creditAccountListPaymentSchedules(
    $accountToken: String!
    $frequency: String
    $statuses: String
    $count: Int
    $start_index: Int
  ) {
    creditAccountListPaymentSchedules(
      accountToken: $accountToken
      frequency: $frequency
      statuses: $statuses
      count: $count
      start_index: $start_index
    ) {
      count
      start_index
      end_index
      is_more
      data {
        token
        amountCategory
        paymentSourceToken
        accountToken
        status
        amount
        frequency
        paymentDay
        nextPaymentImpactDate
        currencyCode
        description
        createdTime
        updatedTime
      }
    }
  }
`;

// =========Payment mutations=========
export const updatePaymentSourceMutation = gql`
  mutation updatePaymentSource($token: String!, $status: CreditPaymentSourceUpdateInput!) {
    updatePaymentSource(token: $token, status: $status) {
      token
      sourceType
      name
      routingNumber
      bankName
      status
    }
  }
`;

// =============== Statement Queries =================
export const getCreditAccountStatementsQuery = gql`
  query creditAccountStatements($accountToken: String!) {
    creditAccountStatements(accountToken: $accountToken) {
      data {
        token
        daysInBillingCycle
        openingDate
        closingDate
        closingBalance
      }
    }
  }
`;

export const getCreditProgramDetailsQuery = gql`
  query getCreditProgramForActiveShortCode($start_index: Int) {
    getCreditProgramForActiveShortCode(start_index: $start_index) {
      shortCode
      name
      partner
      statementPdfEnabled
      createdTime
      updatedTime
    }
  }
`;

export const getStatementFileDetails = gql`
  query creditAccountStatementFiles($accountToken: String!, $statementSummaryToken: String!) {
    creditAccountStatementFiles(
      accountToken: $accountToken
      statementSummaryToken: $statementSummaryToken
    ) {
      data {
        type
        signedUrl
      }
    }
  }
`;

export const getStatementDetailsTransactionsRewardsPaymentInfoQuery = gql`
  query statementDetailsTransactionsRewardsPaymentInfo($accountToken: String!, $token: ID!) {
    creditAccountStatement(accountToken: $accountToken, token: $token) {
      token
      accountToken
      openingBalance
      closingBalance
      creditLimit
      availableCredit
      pastDueAmount
      purchases
      interest
      fees
      credits
      payments
      daysInBillingCycle
      gracePeriod
      paymentDueDate
      openingDate
      closingDate
      isCurrent

      paymentInfo {
        token
        newStatementBalance
        minimumPaymentDue
        paymentDueDate
      }

      transactions {
        data {
          createdTime
          memo
          amount
          currencyCode
          group
        }
      }
      rewards {
        previousMonthReward
        currentMonthReward
        adjustedReward
        totalReward
      }

      yearToDate {
        totalReward
        totalFees
        totalInterest
      }
    }

    creditAccount(token: $accountToken) {
      accountCards {
        token
        last_four
        instrument_type
        cvv_number
        state
        expiration_time
      }
      creditLine {
        currencyCode
      }

      accountHolderDetails {
        first_name
        last_name
      }

      token
    }
  }
`;

// =============== Credit Product Queries =================
export const cardProductsQuery = `
  cardProducts(count: 100) {
      data {
        name
        active
        token
      }
    }
`;

export const productFragment = `
  name
  description
  status
  cardProductTokens
  creditLine {
    min
    max
  }
  usage
  classification
  minPaymentPercentage
  minPaymentFlatAmount
  productType
  productSubType
  currencyCode
  paymentAllocationOrder
  interestCalculation {
    method
    dayCount
    interestApplication
    minimumInterest
    interestOnGraceReactivation
    graceDaysApplication
    applicationOfCredits {
      cycleType
      day
    }
    excludeTranTypes
  }
  config {
    billingCycleDay
    paymentDueDay
    fees
    periodicFees {
      frequency
      numberOfDaysPostActivation
    }
  }
  includeInMinimumPayment
`;

export const productDefaultsQuery = `
  productDefaults {
    ${productFragment}
  }
`;

export const productFieldsFragment = `
  cardProductTokens  
  creditLine {
    min
    max
  }
  currencyCode
  description
  minPaymentFlatAmount
  minPaymentPercentage
  status
  submissionDate
  token
  updatedTime
  name
`;

export const getCreditProductsQuery = gql`
  query getAllCreditProducts($count: Int) {
    creditProducts(count: $count) {
      data {
        ${productFieldsFragment}
      }
    }
  }
`;

export const creditProductQuery = `
  creditProduct(token: $token) {
    token
    ${productFragment}
    createdTime
    updatedTime
    history {
      operation
      username
      userRole
      createdTime
      newStatus
      comment
    }
    cardProducts {
      name
      token
    }

    rewards
    includeInMinimumPayment

    parentProductToken

    originalCreditProduct @include(if: $isApprover) {
      token
      name
      description
      includeInMinimumPayment
      currencyCode
      creditLine {
        min
        max
      }
      minPaymentPercentage
      minPaymentFlatAmount
      cardProductTokens
      cardProducts {
        token
        name
      }
      config {
        periodicFees {
          frequency
          numberOfDaysPostActivation
        }
      }
    }
  }
`;

export const creditProductsLineageQuery = `
  creditProductsLineage(token: $token) {
    data {
      token
      status
      createdTime
    }
  }
`;

const creditProductInputParams = `
  $cardProductTokens: [String]!,
  $name: String!,
  $description: String,
  $classification: ProductClassification,
  $status: String!,
  $creditLineMin: Float,
  $creditLineMax: Float,
  $usage: [String],
  $minPaymentPercentage: Float,
  $minPaymentFlatAmount: Float,
  $interestApplication: [String],
  $minimumInterest: Float,
  $fees: [String],
  $periodicFees: [PeriodicFeeInput],
  $comment: String
  $includeInMinimumPayment: [MIN_PAYMENT_OPTIONS]
  $rewards: Boolean
`;

const creditProductMutationParams = `
  cardProductTokens: $cardProductTokens
  name: $name
  classification: $classification
  description: $description
  status: $status
  creditLineMin: $creditLineMin
  creditLineMax: $creditLineMax
  usage: $usage
  minPaymentPercentage: $minPaymentPercentage
  minPaymentFlatAmount: $minPaymentFlatAmount
  interestApplication: $interestApplication
  minimumInterest: $minimumInterest
  fees: $fees
  periodicFees: $periodicFees
  comment: $comment
  includeInMinimumPayment: $includeInMinimumPayment
  rewards: $rewards
`;

const createCreditProductCloneInputParams = `
  $token: String!
  $cardProductTokens: [String]!,
  $name: String!,
  $description: String,
  $classification: ProductClassification,
  $status: String!,
  $creditLineMin: Float,
  $creditLineMax: Float,
  $usage: [String],
  $minPaymentPercentage: Float,
  $minPaymentFlatAmount: Float,
  $interestApplication: [String],
  $minimumInterest: Float,
  $fees: [String],
  $periodicFees: [PeriodicFeeInput],
  $comment: String
  $includeInMinimumPayment: [MIN_PAYMENT_OPTIONS]
  $rewards: Boolean
`;

const createCreditProductCloneMutationParams = `
  token: $token
  cardProductTokens: $cardProductTokens
  name: $name
  description: $description
  classification: $classification
  status: $status
  creditLineMin: $creditLineMin
  creditLineMax: $creditLineMax
  usage: $usage
  minPaymentPercentage: $minPaymentPercentage
  minPaymentFlatAmount: $minPaymentFlatAmount
  interestApplication: $interestApplication
  minimumInterest: $minimumInterest
  fees: $fees
  periodicFees: $periodicFees
  comment: $comment
  includeInMinimumPayment: $includeInMinimumPayment
  rewards: $rewards
`;

export const createCreditProductMutation = gql`
  mutation createCreditProduct(
    ${creditProductInputParams}
  ) {
    createCreditProduct(
      ${creditProductMutationParams}
    ) {
      token
      status
    }
  }
`;

export const createCreditProductCloneMutation = gql`
  mutation createCreditProductClone(
    ${createCreditProductCloneInputParams}
  ) {
    createCreditProductClone(
      ${createCreditProductCloneMutationParams}
    ) {
      token
      parentProductToken
      status
    }
  }
`;

export const updateCreditProductMutation = gql`
  mutation updateCreditProduct(
    $token: ID!
    ${creditProductInputParams}
  ) {
    updateCreditProduct(
      token: $token
      ${creditProductMutationParams}
    ) {
      token
      status
    }
  }
`;

export const updateCreditProductStatusMutation = gql`
  mutation updateCreditProductStatus(
    $token: ID!
    $status: CREDIT_PRODUCT_STATUS!
    $comment: String
  ) {
    updateCreditProductStatus(token: $token, status: $status, comment: $comment) {
      token
      status
    }
  }
`;

export const activateCreditProductCloneMutation = gql`
  mutation activateCreditProductClone($token: ID!, $comment: String) {
    activateCreditProductClone(token: $token, comment: $comment) {
      token
      status
    }
  }
`;

// Activity logs
export const activityLogsQuery = `
  activityLogs(token: $token, entityType: $entityType) {
    data {
      token
      userInitials
      email
      description
      createdTime
      note
    }
  }
`;

export const getActivityLogsQuery = gql`
  query activityLogs($token: ID!, $entityType: ENTITY_TYPE!) {
    activityLogs(token: $token, entityType: $entityType) {
      data {
        token
        userInitials
        email
        description
        createdTime
        note
      }
    }
  }
`;

export const getOffersQuery = gql`
  query offers($start_index: Int, $filters: OffersFilters, $activeTab: TAB_NAMES_MAP) {
    offers(start_index: $start_index, filters: $filters, activeTab: $activeTab) {
      data {
        token
        status
        name
        productToken {
          token
          name
        }
        gracePeriodDays
        paymentDueDay
        createdTime
        updatedTime
      }
      count
      start_index
      end_index
      is_more
    }
  }
`;

export const getOffersPresence = gql`
  query offersPresence {
    offersPresence {
      active
      queue
      rejected
    }
  }
`;

// =========== Bundles Queries ===========
export const bundlesQuery = gql`
  query bundles($count: Int, $status: [BUNDLE_STATUS]) {
    bundles(count: $count, status: $status) {
      data {
        token
        name
        status
        createdTime
      }
    }
  }
`;

// =========== APRs Queries ===========
export const aprPolicyFragment = `
    token
    name
    description
    purchases {
      name
      tiers {
        marginRate
        apr
      }
    }
    effectiveDate
    createdTime
`;

export const aprPolicyQuery = gql`
    query getAprPolicy($token: ID!) {
        aprPolicy(token: $token) {
            ${aprPolicyFragment}
        }
    }
`;

export const aprPricingStrategiesQuery = `
  pricingStrategies {
    data {
      strategyId
      displayName
    }
  }
`;

export const aprPricingStrategyQuery = gql`
  query pricingStrategy($token: ID!) {
    pricingStrategy(token: $token) {
      strategyId
      displayName
      tiers {
        pricingTier
        margin
      }
    }
  }
`;

export const aprPrimeRateQuery = `
  primeRate {
    rate
    effectiveDate
    determinationDate
  }
`;

export const assetCreationPrequisiteQuery = gql`
  query assetCreationPrequisite {
    ${aprPrimeRateQuery}
  }
`;

// =========== APRs Mutations ===========
const createAprPolicyInputParams = `
  $token: ID,
  $name: String!,
  $description: String,
  $purchases: APRPolicyPurchaseInput!
`;

const createAprPolicyMutationParam = `
   token: $token,
   name: $name,
   description: $description,
   purchases: $purchases,
`;

export const createAprPolicyMutation = gql`
  mutation aprPolicyCreate(${createAprPolicyInputParams}) {
    aprPolicyCreate(${createAprPolicyMutationParam}) {
      token
      createdTime
    }
  }
`;

// =========== Fee Policy Queries ===========
export const feePolicyFragment = `
    token
    name
    description
    account {
        latePayment {
            defaultValue
            defaultMethod
        }
        returnedPayment {
            defaultValue
            defaultMethod
        }
        foreignTransactionFee {
            defaultValue
            defaultMethod
        }
        monthlyFee {
          feeAmount
          numberOfDaysPostActivation
          excludeFromInterestCalculation
        }
        annualFee {
          feeAmount
          numberOfDaysPostActivation
          excludeFromInterestCalculation
        }
    }
`;

export const feePolicyQuery = gql`
    query getFeePolicy($token:ID!) {
        feePolicy(token: $token) {
            ${feePolicyFragment}
        }
    }
`;

// =========== Fee Policy Mutations ===========
const createFeePolicyInputParams = `
  $token: ID,
  $name: String!,
  $description: String,
  $account: FeePolicyAccountInput!,
`;

const createFeePolicyMutationParam = `
   token: $token,
   name: $name,
   description: $description,
   account: $account
`;

export const createFeePolicyMutation = gql`
  mutation feePolicyCreate(${createFeePolicyInputParams}) {
    feePolicyCreate(${createFeePolicyMutationParam}) {
      token
      createdTime
    }
  }
`;

// =========== Credit Product Policy Mutations ===========
export const creditProductPolicyQuery = gql`
  query getCreditProductPolicy($token:ID!) {
    creditProductPolicy(token: $token) {
      ${productPolicyFragment}
    }
  }
`;

// =========== Credit Product Policy Mutations ===========
const createCreditProductPolicyInputParams = `
    $token: ID,
    $name: String!,
    $description: String,
    $classification: CLASSIFICATION!,
    $productType: PRODUCT_TYPE!,
    $productSubType: PRODUCT_SUB_TYPE!,
    $currencyCode: CURRENCY_CODE!,
    $creditLine: CreditLineInput!,
    $usage: [USAGE]!,
    $interestCalculation: InterestCalculationProductPolicyInput!,
    $payments: PaymentsProductPolicyInput!
    $cardProducts: [CardProductForPolicyInput]!
`;

const createCreditProductPolicyMutationParam = `
    token: $token,
    name: $name,
    description: $description,
    cardProducts: $cardProducts,
    classification: $classification,
    productType: $productType,
    productSubType: $productSubType,
    currencyCode: $currencyCode,
    creditLine: $creditLine,
    usage: $usage,
    interestCalculation: $interestCalculation,
    payments: $payments
`;

export const createCreditProductPolicyMutation = gql`
  mutation creditProductPolicyCreate(${createCreditProductPolicyInputParams}) {
      creditProductPolicyCreate(${createCreditProductPolicyMutationParam}) {
        token
        createdTime
    }
  }
`;

// =========== Reward Policy Queries ===========

export const rewardPolicyFragment = `
    token
    name
    description
    rules {
      type
      filters {
        amount {
          greaterThan
          lessThan
        }
      }
      outcome {
        percentage
      }
    }
`;

export const rewardPolicyQuery = gql`
    query getRewardPolicy($token:ID!) {
        rewardPolicy(token: $token) {
            ${rewardPolicyFragment}
        }
    }
`;

// =========== Fee Policy Mutations ===========
const createRewardPolicyInputParams = `
  $token: ID
  $name: String!
  $description: String
  $rules: [RewardRuleInput]!
`;

const createRewardPolicyMutationParam = `
   token: $token,
   name: $name,
   description: $description,
   rules: $rules
`;

export const createRewardPolicyMutation = gql`
  mutation rewardPolicyCreate(${createRewardPolicyInputParams}) {
    rewardPolicyCreate(${createRewardPolicyMutationParam}) {
      token
      createdTime
    }
  }
`;

// =========== Credit Document Policy Mutations ===========
const createDocumentPolicyInputParams = `
    $token: ID,
    $name: String!,
    $summaryOfCreditTerms: DocumentPolicyTemplateAndAssetInput!
    $accountStatement: DocumentPolicyTemplateInput!
    $rewardsDisclosure: DocumentPolicyTemplateAndAssetInput
    $termsSchedule: DocumentPolicyTemplateInput!
    $benefitsDisclosureTraditional: DocumentPolicyAssetInput!
    $benefitsDisclosurePremium: DocumentPolicyAssetInput!
    $noaaSingleReason: DocumentPolicyTemplateInput!
    $noaaSingleReasonWithDoddFrank: DocumentPolicyTemplateInput!
    $noaaMultipleReasonWithDoddFrank: DocumentPolicyTemplateInput!
    $privacyPolicy: DocumentPolicyAssetInput!
    $eDisclosure: DocumentPolicyAssetInput!
    $cardMemberAgreement: DocumentPolicyAssetInput!
`;

const createDocumentPolicyMutationParam = `
    token: $token,
    name: $name,
    summaryOfCreditTerms: $summaryOfCreditTerms
    accountStatement: $accountStatement
    rewardsDisclosure: $rewardsDisclosure
    termsSchedule: $termsSchedule
    benefitsDisclosureTraditional: $benefitsDisclosureTraditional
    benefitsDisclosurePremium: $benefitsDisclosurePremium
    noaaSingleReason: $noaaSingleReason
    noaaSingleReasonWithDoddFrank: $noaaSingleReasonWithDoddFrank
    noaaMultipleReasonWithDoddFrank: $noaaMultipleReasonWithDoddFrank
    privacyPolicy: $privacyPolicy
    eDisclosure: $eDisclosure
    cardMemberAgreement: $cardMemberAgreement
`;

export const createDocumentPolicyMutation = gql`
  mutation documentPolicyCreate(${createDocumentPolicyInputParams}) {
    documentPolicyCreate(${createDocumentPolicyMutationParam}) {
      token
      createdTime
    }
  }
`;

// =========== File template service queries ===========
export const getFileTemplateAssetsQuery = gql`
  query fileTemplateAssets($referenceGroups: [String], $aggregatedBy: String) {
    fileTemplateAssets(referenceGroups: $referenceGroups, aggregatedBy: $aggregatedBy) {
      data {
        token
        state
        templateToken
        referenceGroup
        signedUrl {
          pdf
          png
        }
        createdTime
      }
    }
  }
`;

export const getFileTemplatesQuery = gql`
  query templates($referenceGroups: String!, $aggregatedBy: String) {
    templates(referenceGroups: $referenceGroups, aggregatedBy: $aggregatedBy) {
      data {
        token
        name
        state
        signedUrl {
          html
          zip
        }
        referenceGroup
        createdTime
      }
    }
  }
`;

export const getAssetsByTokenQuery = gql`
  query fileAssetsByToken($tokens: [String]!) {
    fileAssetsByToken(tokens: $tokens) {
      data {
        token
        referenceGroup
        state
        templateToken
        signedUrl {
          pdf
          png
        }
        createdTime
      }
    }
  }
`;

export const getDelinquencyStateByAccountTokenQuery = gql`
  query creditDelinquencyState($accountToken: String!) {
    creditDelinquencyState(accountToken: $accountToken) {
      accountToken
      isDelinquent
      dateAccountDelinquent
      dateAccountCurrent
      totalDaysPastDue
      totalPastDue
      currentDue
      totalDue
      buckets {
        bucketNumber
        paymentDueDate
        pastDueCarriedForward
        currentDue
        totalDue
        daysPastDue
      }
    }
  }
`;

export const getCollectionsTasksByAccountTokenQuery = gql`
  query creditCollectionsTasks($accountToken: String!) {
    creditCollectionsTasks(accountToken: $accountToken) {
      token
      accountToken
      delinquencyTransitionToken
      triggerTime
      state
      action
      canceledByPaymentTransitionToken
      actionedOn
      createdTime
      updatedTime
      canceledTime
    }
  }
`;

export const getSubStatusQuery = gql`
  query creditSubstatus($token: String!) {
    creditSubstatus(token: $token) {
      token
      resourceToken
      resourceType
      isActive
      subStatus
      state
      createdTime
      updatedTime
      attributes {
        key
        value
      }
      events {
        state
        channel
        reason
        effective_date
        created_time
      }
    }
  }
`;

export const getSubStatusesQuery = gql`
  query creditSubstatuses($start_index: Int, $token: String) {
    creditSubstatuses(start_index: $start_index, token: $token) {
      data {
        token
        resourceToken
        resourceType
        isActive
        subStatus
        state
        createdTime
        updatedTime
      }
    }
  }
`;

// =========== Credit SubStatus Mutations ===========
export const addSubStatusQuery = gql`
  mutation creditCreateSubstatus($subStatus: CreditSubStatusInput!) {
    creditCreateSubstatus(subStatus: $subStatus) {
      token
    }
  }
`;

export const updateSubStatusQuery = gql`
  mutation creditUpdateSubstatus($token: String!, $subStatus: CreditSubStatusUpdateInput!) {
    creditUpdateSubstatus(token: $token, subStatus: $subStatus) {
      token
    }
  }
`;

// =========== File template service Mutations ===========
const fileTemplateAssetCreateInputParams = `
    $templateToken: String!,
    $referenceToken: String!,
    $referenceGroup: String
    $values: String!
`;

const fileTemplateAssetCreateParam = `
    templateToken: $templateToken,
    referenceToken: $referenceToken,
    referenceGroup: $referenceGroup,
    values: $values
`;

export const createfileTemplateAssetMutation = gql`
  mutation fileTemplateAssetCreate(${fileTemplateAssetCreateInputParams}) {
    fileTemplateAssetCreate(${fileTemplateAssetCreateParam}) {
      token
      referenceGroup
      state
    }
  }
`;

export const getCreditUserDetailsQuery = gql`
  query creditUserDetails($token: String!) {
    creditUserDetails(user_token: $token) {
      token
      firstName
      lastName
      email
      phone
      address1
      address2
      city
      state
      country
      zip
      creditAccounts {
        token
        isPrimary
      }
    }
  }
`;
