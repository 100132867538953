const CREDIT_ROUTES = {
  CREDIT: '/credit',

  PRODUCT: '/credit/credit-product',
  PRODUCTS: '/credit/credit-products',
  PRODUCT_CREATE: '/credit/credit-products/create',

  SUB_STATUS: '/credit/account/substatus',
  ACCOUNT: '/credit/account',
  ACCOUNTS: '/credit/accounts',
  USERS: '/credit/users',
  USER: '/credit/user',

  ACQUISITION_OFFER: '/credit/acquisition-offer',
  ACQUISITION_OFFERS: '/credit/acquisition-offers',
  ACQUISITION_OFFER_CREATE: '/credit/acquisition-offers/create',

  BUNDLE: '/credit/bundle',
  BUNDLES: '/credit/bundles',
  BUNDLES_CREATE: '/credit/bundles/create',

  WORK_QUEUE: '/credit/work-queue',
  APPROVAL_QUEUE: '/credit/approval-queue',

  POLICY_LIBRARY: '/credit/policy-library',
  POLICIES: '/credit/policies/',
  POLICY_CREATE: '/credit/policy-library/create',

  POLICY_PRODUCT: '/credit/policy-library/credit-product',
  POLICY_PRODUCTS: '/credit/policy-library/credit-products',

  POLICY_APR: '/credit/policy-library/apr',
  POLICY_APRS: '/credit/policy-library/aprs',

  POLICY_FEE: '/credit/policy-library/fee',
  POLICY_FEES: '/credit/policy-library/fees',

  POLICY_REWARD: '/credit/policy-library/reward',
  POLICY_REWARDS: '/credit/policy-library/rewards',

  TEMPLATES: '/credit/templates',
};

export default CREDIT_ROUTES;
