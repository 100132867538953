const RouteFactoryMap = {
  rootRedirect: () =>
    import(/*webpackChunkName: "Root Redirect" */ './../../views/root-redirect/RootRedirectView'),
  applicationReset: () =>
    import(
      /*webpackChunkName: "Application Reset" */ './../../views/application-reset/ApplicationResetView'
    ),
  home: () => import(/*webpackChunkName: "Home" */ './../../views/home/HomeView'),
  achManagement: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  admin: () => import(/*webpackChunkName: "Admin" */ './../../views/admin/AdminView'),
  adminRoles: () => import(/*webpackChunkName: "Admin" */ './../../views/admin/AdminView'),
  adminDepartments: () => import(/*webpackChunkName: "Admin" */ './../../views/admin/AdminView'),
  adminSupplements: () => import(/*webpackChunkName: "Admin" */ './../../views/admin/AdminView'),
  adminConfigManagement: () =>
    import(/*webpackChunkName: "Admin" */ './../../views/admin/AdminView'),
  adminSandboxesManagement: () =>
    import(
      /*webpackChunkName: "SandboxesManagement" */
      '../../views/admin/SandboxesManagement'
    ),
  apiAccess: () =>
    import(/*webpackChunkName: "ApiAccess" */ './../../views/api-access/ApiAccessView'),
  auditLogs: () =>
    import(/*webpackChunkName: "AuditLogs" */ './../../views/audit-logs/AuditLogsView'),
  authCallback: () =>
    import(/*webpackChunkName: "AuthCallback" */ './../../views/auth-callback/AuthCallbackView'),
  authError: () =>
    import(/*webpackChunkName: "AuthError" */ './../../views/error-views/AuthErrorView'),
  binManagement: () =>
    import(
      /*webpackChunkName: "BinManagement" */ '../../views/bin-management/SummaryView/SummaryView.js'
    ),
  binManagementBinView: () =>
    import(/*webpackChunkName: "BinManagement" */ '../../views/bin-management/BinView/BinView.js'),
  binManagementRangeView: () =>
    import(
      /*webpackChunkName: "BinManagement" */ '../../views/bin-management/RangeView/RangeView.js'
    ),
  binManagementProgramView: () =>
    import(
      /*webpackChunkName: "BinManagement" */ '../../views/bin-management/ProgramView/ProgramView.js'
    ),
  binDataManagement: () =>
    import(
      /*webpackChunkName: "BinDataManagement" */ '../../views/bin-data-management/LandingPageView/LandingPageView.js'
    ),
  binDataManagementUpdateRange: () =>
    import(
      /*webpackChunkName: "BinDataManagement" */ '../../views/bin-data-management/RangeEditPageView/RangeEditPageView.js'
    ),
  binDataManagementUpdateBin: () =>
    import(
      /*webpackChunkName: "BinDataManagementUpdateBin" */ '../../views/bin-data-management/BinEditPageView/BinEditPageView'
    ),
  binDataManagementCreateBin: () =>
    import(
      /*webpackChunkName: "BinDataManagementCreateBin" */ '../../views/bin-data-management/BinPageView/CreateBinPageView'
    ),
  binDataManagementCreateRange: () =>
    import(
      /*webpackChunkName: "BinDataManagementCreateRange" */ '../../views/bin-data-management/RangePageView/RangePageView'
    ),
  binDataManagementDetailsBin: () =>
    import(
      /*webpackChunkName: "BinDataManagementBinDetails" */ '../../views/bin-data-management/BinDetailsPageView/BinDetailsPageView'
    ),
  binDataManagementDetailsRange: () =>
    import(
      /*webpackChunkName: "BinDataManagementRangeDetails" */ '../../views/bin-data-management/RangeDetailsPageView/RangeDetailsPageView'
    ),
  contactCS: () =>
    import(/*webpackChunkName: "ContactCS" */ './../../views/contact-cs/ContactCSView'),
  development: () =>
    import(/*webpackChunkName: "Development" */ '../../views/development/DevelopmentView'),
  developmentTransactionTimeline: () =>
    import(
      /*webpackChunkName: "Development Transaction Trainer" */ '../../views/development/transaction-timeline/TransactionTimeline'
    ),
  developmentWebhooksOverview: () =>
    import(
      /*webpackChunkName: "Development Webhooks Overview" */ '../../views/development/WebhooksOverview'
    ),
  developmentWebhooksNew: () =>
    import(/*webpackChunkName: "Development Webhooks New" */ '../../views/development/WebhooksNew'),
  tokenization: () =>
    import(/*webpackChunkName: "Tokenization" */ '../../views/tokenization/TokenizationView'),
  tokenizationDetail: () =>
    import(
      /*webpackChunkName: "Tokenization Detail" */ '../../views/tokenization/TokenizationView'
    ),
  directDeposit: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  notFoundView: () =>
    import(/*webpackChunkName: "NotFoundView" */ './../../views/error-views/NotFoundView'),
  reports: () => import(/*webpackChunkName: "Reports" */ './../../views/reports/ReportsView'),
  reportsGuide: () =>
    import(/*webpackChunkName: "Reports" */ './../../views/reports/ReportsGuideView'),
  reportsDataDictionary: () =>
    import(/*webpackChunkName: "Reports" */ './../../views/reports/ReportsDataDictionaryView'),
  diDataCatalog: () =>
    import(
      /*webpackChunkName: "Data Marketplace" */ './../../views/data-catalog/ProductCatalogView'
    ),
  credit: () => import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditTemplateEditor: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditWelcome: () => import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditBundle: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/credit-bundles/CreditBundlesView'
    ),
  creditBundles: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/credit-bundles/CreditBundlesView'
    ),
  creditBundlesCreate: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/credit-bundles/CreditBundlesView'
    ),
  creditBundleWorkQueue: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/credit-bundles/CreditBundlesView'
    ),
  creditPolicyCreate: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/policy-library/PolicyLibraryView'
    ),
  creditPolicyLibrary: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/policy-library/PolicyLibraryView'
    ),
  creditPolicyLibraryCreditProductsDashboard: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/policy-library/PolicyLibraryView'
    ),
  creditPolicyLibraryCreditProduct: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/policy-library/PolicyLibraryView'
    ),
  creditPolicyLibraryAPRsDashboard: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/policy-library/PolicyLibraryView'
    ),
  creditPolicyLibraryAPR: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/policy-library/PolicyLibraryView'
    ),
  creditPolicyLibraryFeesDashboard: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/policy-library/PolicyLibraryView'
    ),
  creditPolicyLibraryFee: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/policy-library/PolicyLibraryView'
    ),
  creditPolicyLibraryRewardsDashboard: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/policy-library/PolicyLibraryView'
    ),
  creditPolicyLibraryReward: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/policy-library/PolicyLibraryView'
    ),
  creditPolicyLibraryOffersDashboard: () =>
    import(
      /*webpackChunkName: "Credit" */ './../../views/credit/pages/policy-library/PolicyLibraryView'
    ),
  creditProductsHistory: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditProductApprovalQueue: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditProductsActivity: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditProduct: () => import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditProducts: () => import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditProductsRejected: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditOffer: () => import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditOffers: () => import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditOffersHistory: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditProductCreate: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditOfferCreate: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditAccountSubstatus: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditAccount: () => import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditAccounts: () => import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditUsers: () => import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditUser: () => import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditAccountCard: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditDigitalWalletToken: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditAccountPaymentInitiation: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditAccountPaymentDetails: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditPaymentSourceDetails: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditAccountPaymentAccount: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditAccountStatementDetails: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditAccountTransactionDetails: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/CreditView'),
  creditTemplates: () =>
    import(/*webpackChunkName: "Credit" */ './../../views/credit/pages/templates/TemplatesView'),
  scheduler: () =>
    import(/*webpackChunkName: "Scheduler" */ './../../views/scheduler/SchedulerView'),
  userProfile: () =>
    import(/*webpackChunkName: "UserProfile" */ './../../views/user-profile/UserProfileView'),
  userActivityLogs: () =>
    import(/*webpackChunkName: "UserProfile" */ './../../views/user-profile/UserProfileView'),
  userProfileSecuritySettings: () =>
    import(/*webpackChunkName: "UserProfile" */ './../../views/user-profile/UserProfileView'),
  userProfilePermissions: () =>
    import(/*webpackChunkName: "UserProfile" */ './../../views/user-profile/UserProfileView'),
  pinReveal: () =>
    import(/*webpackChunkName: "UserProfile" */ './../../views/program/ProgramView/ProgramView'),
  program: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholders: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCommandoModes: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCommandoMode: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programBusinesses: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardholders: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programBusiness: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardholder: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardholderAccount: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAchFunding: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAchFundingTransfer: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAchFundingTransferFunds: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholder: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholderActivity: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholderAccount: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholderAccountActivity: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholderCards: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholderCard: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholderCardActivity: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholderActivate: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholderSuspend: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholderTerminate: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAccountholderTransaction: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCard: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardCreation: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardProducts: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardProduct: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programBulkCardProducts: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programBulkIssuance: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardManagementProviders: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardManagementInventory: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programHSMKeys: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programAssignHSMKeys: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programBulkIssuanceCreate: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programBulkIssuanceDetail: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programBalance: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardOrderAndShipment: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programInventoryCardPackages: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programInventoryOrderSummary: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programInventorySku: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programProvider: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programProviderCreate: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programProviders: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programReviews: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programReviewDetails: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programSku: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programSkuCreate: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programSkus: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardPackage: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardPackageCreate: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programCardPackages: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  programDigitalWalletToken: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
  risk: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskAlerts: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskCaseManagement: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskDecisionManager: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskDisputes: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskBulkUpload: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskDispute: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskNewDispute: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskBankReporting: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskFraudStream: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskFraudStreamPolicy: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskFraudStreamTransactions: () =>
    import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskFraudStreamHealth: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskFraudStreamHistory: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskFraudStreamHome: () => import(/*webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskTransactionReport: () => import(/* webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskRealTimeDecisioning: () =>
    import(/* webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  riskRealTimeDecisioningDashboard: () =>
    import(/* webpackChunkName: "Risk" */ './../../views/risk/RiskView'),
  threeDS: () =>
    import(/*webpackChunkName: "Program" */ './../../views/program/ProgramView/ProgramView'),
};

export default RouteFactoryMap;
