import { action, decorate } from 'mobx';
import { secureStorage, flipFlop } from '@mq/volt-amc-container';

export default class ParentStore {
  // actions

  usePathOrAppApiProxy(path) {
    return flipFlop.get('app-api-proxy-beta', false) && window.env.APP_API_URL
      ? `${window.env.APP_API_URL}${path}`
      : path;
  }

  setAttr(attribute: string, value: any) {
    this[attribute] = value;
  }

  setAttrs(attributes: Object) {
    for (const attribute in Object.keys(attributes)) {
      this[attribute] = attributes[attribute];
    }
  }

  getAttr(attribute: string) {
    return this[attribute];
  }

  loadItem(key: string, config: Object) {
    if (typeof config[key] !== 'undefined') {
      this[key] = config[key];
    }
  }

  toggleValueInAttrArray(attribute: string, value: any) {
    // set as array if not an array
    if (!this[attribute].push) {
      this[attribute] = [];
    }
    const currentIndex = this[attribute].indexOf(value);
    if (currentIndex === -1) {
      this[attribute].push(value);
    } else {
      this[attribute].splice(currentIndex, 1);
    }
  }

  setAndSaveAttr(attribute: string, value: any) {
    this[attribute] = value;
    secureStorage.setItem(attribute, value);
  }

  load(vals) {
    for (const val in vals) {
      this[val] = vals[val];
    }
  }

  // helpers
  mapToJson(map) {
    const out = Object.create(null);
    map.forEach((value, key) => {
      if (value instanceof Map) {
        out[key] = this.mapToJson(value);
      } else {
        out[key] = value;
      }
    });
    return out;
  }

  mobxObjectToJs(object) {
    if (object && object.toJS) {
      return object.toJS();
    } else {
      return [];
    }
  }

  mobxArrayToJs(array) {
    if (array && array.toJS) {
      return array.toJS();
    } else {
      return [];
    }
  }

  mobxMapToJsObject(map) {
    let obj = {};
    map.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  renameLocalStorageItem(from: string, to: string) {
    const value = secureStorage.getItem(from);
    const setValue = value ? value : '';
    secureStorage.setItem(to, setValue);
    secureStorage.removeItem(from);
  }

  clone() {
    let attributes = {};
    for (const value in this.$mobx.values) {
      if (this.$mobx.values.hasOwnProperty(value)) {
        // intended for child stores
        let attributeValue = this[value];
        if (typeof attributeValue !== 'undefined') {
          if (attributeValue && attributeValue.toJS) {
            attributeValue = attributeValue.toJS();
          }
          if (typeof attributeValue !== 'function') {
            const computedValue = this.$mobx.values[value].isMobXComputedValue;
            if (!computedValue) {
              attributes[value] = attributeValue;
            }
          }
        }
      }
    }
    const cloneStore = new this.constructor();
    cloneStore.load(attributes);
    return cloneStore;
  }

  makeId(length = 12) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    for (let i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

  stringToParam(string: string) {
    return string ? string.replace(/\s+/g, '-').toLowerCase() : '';
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  hashString(str) {
    return str
      .split('')
      .reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
  }

  casedStringsEqual(a, b) {
    if (a === b) return true;
    if (typeof a !== 'string' || typeof b !== 'string') return false;
    return a.toLowerCase() === b.toLowerCase();
  }

  /**
   * Does exactly what ruby dig() does.
   * Attempts to find a nested value, if it's not found returns undefined
   */
  dig(result: Object, ...keys) {
    let digged = result;
    for (const key of keys) {
      if (typeof digged === 'undefined' || digged === null) {
        return undefined;
      }
      if (typeof key === 'function') {
        digged = key(digged);
      } else {
        digged = digged[key];
      }
    }
    return digged;
  }
}

decorate(ParentStore, {
  // actions
  setAttr: action.bound,
  setAttrs: action.bound,
  toggleValueInAttrArray: action.bound,
  setAndSaveAttr: action.bound,
  load: action.bound,
  loadItem: action.bound,
});
