export const FAVORITE_PROGRAMS_KEY = 'favoritePrograms';
export const SELECTED_ACTIVE_PROGRAM_KEY = 'selectedActiveProgram';
export const PROGRAMS_META_DATA_KEY = 'userMetadata-programs';

export const TOGGLE_FAVORITE_PROGRAM = 'TOGGLE_FAVORITE_PROGRAM';
export const SELECT_ACTIVE_PROGRAM = 'SELECT_ACTIVE_PROGRAM';
export const FILTER = 'FILTER';
export const PROGRAMS = 'PROGRAMS';
export const PROGRAMS_META_DATA = 'PROGRAMS_META_DATA';
export const SELECT_ACTIVE_PROGRAM_DNA = 'SELECT_ACTIVE_PROGRAM_DNA';
export const SELECT_ACTIVE_PROGRAM_CONFIGS = 'SELECT_ACTIVE_PROGRAM_CONFIGS';
export const SELECT_ACTIVE_PROGRAM_DNA_KEY = 'dnaPrograms';
export const SELECT_ACTIVE_PROGRAM_CONFIGS_KEY = 'programConfigs';
export const MARQETA = 'marqeta';
export const SUTTON = 'sutton';
