// @flow
import logger from 'utils/logger';
import { action, decorate } from 'mobx';
import ParentApi from './ParentApi.js';
import axios from 'axios';
import notificationStore from 'stores/NotificationStore';
import logOut from '../utils/logOut.js';
import { datadogRum } from '@datadog/browser-rum';

const BAD_REQUEST = 400;

class JanusApi extends ParentApi {
  // values
  prefix: string = window.env.JANUS_API_PREFIX ? window.env.JANUS_API_PREFIX : '';

  // actions
  getData(path: string, params: ?Object, viewStore: ?any) {
    return this.wrapCall(path, params, viewStore, 'get');
  }

  postData(path: string, params: ?Object, viewStore: ?any, headers: ?Object) {
    return this.wrapCall(path, params, viewStore, 'post', headers);
  }

  putData(path: string, params: ?Object, viewStore: ?any) {
    return this.wrapCall(path, params, viewStore, 'put');
  }

  get(path: string, params: ?Object) {
    if (this.validateProgressiveMfa) {
      const defaultHeaders = this.defaultHeaders;
      return axios.get(path, {
        ...defaultHeaders,
        params: params,
      });
    }
  }

  post(path: string, data: ?Object, headers: ?Object = null) {
    if (this.validateProgressiveMfa) {
      const defaultHeaders = headers ? headers : this.defaultHeaders;
      return axios(path, {
        method: 'post',
        ...defaultHeaders,
        data: data,
        // Do not throw error from axios
        // We have to handle bad request errors with additional logic because axios does not return the error object
        // Instead of that axios sends generic message of string type by default, which does not contain necessary data
        validateStatus: () => true,
      });
    }
  }

  put(path: string, data: ?Object) {
    if (this.validateProgressiveMfa) {
      const defaultHeaders = this.defaultHeaders;
      return axios(path, {
        method: 'put',
        ...defaultHeaders,
        data: data,
      });
    }
  }

  wrapCall = async function (
    path: string,
    params: ?Object,
    viewStore: ?any,
    method: string,
    headers: ?Object
  ) {
    let response = null;
    let errorMessage = null;
    // path prefix
    if (this.prefix) {
      path = this.usePathOrAppApiProxy(`${this.prefix}${path}`);
    }
    try {
      if (method === 'get') {
        response = await this.get(path, params);
      } else if (method === 'post') {
        response = await this.post(path, params, headers);
      } else if (method === 'put') {
        response = await this.put(path, params);
      }
      if (response) {
        if (response.status === BAD_REQUEST) {
          errorMessage = `[DEBUG-AUTH (amc) - JanusApi] Janus returned a 400 error when attempting to fetch an auth cookie response.`;
          this.createNewDataDogError(errorMessage);
          logger.log(errorMessage);
          throw response;
        }
        if (this.isExpiredAuthCookieResponse(response)) {
          errorMessage = `[DEBUG-AUTH (amc) - JanusApi] Janus returned ${response.data?.code}: ${response.data?.message} which indicates an expired auth cookie response; logging user out`;
          this.createNewDataDogError(errorMessage);
          logger.log(errorMessage);
          logOut();
        } else if (this.responseError(response)) {
          if (viewStore && viewStore.notify) {
            viewStore.notify('error', this.extractResponseDataError(response));
          } else {
            notificationStore.notify('error', this.extractResponseDataError(response));
          }
          return false;
        } else {
          return this.extractResponseData(response);
        }
      } else {
        if (viewStore && viewStore.notify) {
          viewStore.notify('error', 'Error: Could not access user permissions API');
        } else {
          notificationStore.notify('error', 'Error: Could not access user permissions API');
        }
        return false;
      }
    } catch (e) {
      errorMessage = `[DEBUG-AUTH (amc) - JanusApi] The following error occured in Janus API: ${e}`;
      this.createNewDataDogError(errorMessage);
      logger.error(errorMessage);
      if (e?.status === BAD_REQUEST) {
        throw new Error(`${e?.data?.message}`);
      }
    }
  };

  // helpers

  isExpiredAuthCookieResponse(response: Object) {
    return (
      response && response.data && (response.data.code === 40101 || response.data.code === 440)
    );
  }

  extractResponseData(response: Object) {
    return response.data;
  }

  extractResponseDataError(response: Object) {
    const responseHasData = response.data;
    const responseDataError = response.data.code && response.data.code !== 200;
    if (responseHasData && responseDataError && response.data.message) {
      return response.data.message;
    } else {
      return 'Error in user permissions API';
    }
  }

  createNewDataDogError(message) {
    const datadogError = new Error(message);
    datadogRum.addError(datadogError, { source: 'Janus API' });

    return datadogError;
  }

  responseError(response) {
    const responseCode = response?.data?.code;
    const systemCallResponseSuccess = response?.status && response?.status < 400;
    const responseHasData = response?.data;
    const responseDataError = responseCode && responseCode !== 200;
    if (systemCallResponseSuccess && responseHasData && !responseDataError) {
      return false;
    } else {
      const errorMessage = `[DEBUG-AUTH (amc) - JanusApi] Janus returned an error in its response: {code: ${responseCode}, message: ${response.data?.message}`;
      this.createNewDataDogError(errorMessage);
      logger.error(errorMessage);
      return true;
    }
  }
}

decorate(JanusApi, {
  // actions
  getData: action.bound,
  postData: action.bound,
  putData: action.bound,
  get: action.bound,
  post: action.bound,
  put: action.bound,
});

const janusApi = new JanusApi();
export default janusApi;
